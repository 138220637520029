header#page_header{
  .nav-holder{
    margin-bottom:-30px;
  }
  .navbar{
    color:black;
    background-color: rgba(255,255,255,0.9);
  }


  .rockMenu{
    margin-left: 15px;

    height: 150px;

    .menuItem{
      background-color: rgba(0, 0, 0, 0.5);

      &:first-child{
        border-top-left-radius: 10px;
      }
      &:last-child{
        border-top-right-radius: 10px;
      }


      text-align: center;
      padding: 0;



      a{
        color: orange;
        display: block;
        height: 100%;
        width: 100%;
        padding: 10px;

        &:hover, &.active{
          background-color: black;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }


}