body {
  background-color: #676767;
  background-image: url('/assets/img/bg-hlavni.jpg');
  background-repeat: no-repeat;
  background-position: center top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}
.container-full {
  margin: 0 auto;
  width: 100%;
}
div [class^=col-] {
  padding-top: 5px;
  padding-bottom: 5px;
  /*
background-color: #eee;
background-color: rgba(86,61,124,.15);
border: 1px solid #ddd;
border: 1px solid rgba(86,61,124,.2);*/
}
.galeria > div[class^=col-] {
  padding: 5px;
}
.thumbnail {
  margin: 0;
}
.box,
.box-banner {
  position: relative;
  overflow: hidden;
  height: 280px;
}
@media (max-width: 768px) {
  .box {
    position: relative;
    overflow: hidden;
    height: 450px;
  }
}
@media (max-width: 500px) {
  .box {
    position: relative;
    overflow: hidden;
    height: 340px;
  }
  .box-banner {
    position: relative;
    overflow: hidden;
    height: 180px;
  }
}
.box-big {
  height: 100%;
}
.spb {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
a.thumbnail:hover {
  text-decoration: none;
}
.galeria {
  margin-top: 55px;
}
.galeria .thumbnail {
  position: relative;
}
.galeria .thumbnail:hover > .caption {
  display: block;
}
.galeria .thumbnail .caption {
  margin-top: 15px;
  position: absolute;
  display: none;
  bottom: 4px;
  padding-top: 50px;
  width: 97%;
  color: #fff;
  background-image: -webkit-linear-gradient(top, transparent 0%, #000 100%);
  background-image: -o-linear-gradient(top, transparent 0%, #000 100%);
  background-image: linear-gradient(to bottom, transparent 0%, #000 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#ff000000', GradientType=0);
}
.galeria .thumbnail .caption h3 {
  padding: 0;
  margin: 0;
}
.galeria .thumbnail .caption h3 small {
  color: #fff;
  font-style: italic;
}
.single_gallery footer {
  color: white;
  padding: 5px;
  text-align: center;
}
.single_gallery footer h3 {
  padding: 0;
  margin: 8px 5px;
}
.single_gallery footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header#page_header .nav-holder {
  margin-bottom: -30px;
}
header#page_header .navbar {
  color: black;
  background-color: rgba(255, 255, 255, 0.9);
}
header#page_header .rockMenu {
  margin-left: 15px;
  height: 150px;
}
header#page_header .rockMenu .menuItem {
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 0;
}
header#page_header .rockMenu .menuItem:first-child {
  border-top-left-radius: 10px;
}
header#page_header .rockMenu .menuItem:last-child {
  border-top-right-radius: 10px;
}
header#page_header .rockMenu .menuItem a {
  color: orange;
  display: block;
  height: 100%;
  width: 100%;
  padding: 10px;
}
header#page_header .rockMenu .menuItem a:hover,
header#page_header .rockMenu .menuItem a.active {
  background-color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.pagination-wrapper {
  text-align: center;
}
.pagination-wrapper .pagination li:hover a,
.pagination-wrapper .pagination li:hover span {
  border: 1px solid #000;
  color: #fff;
}
.pagination-wrapper .pagination li.active span,
.pagination-wrapper .pagination li.disabled span {
  background: #d8cd7b;
  border: 1px solid #282828;
}
.pagination-wrapper .pagination li a {
  background: #d8cd7b;
  color: #5d5409;
}
aside [class^=col-] {
  padding: 0;
  margin-top: -5px;
  padding-right: 5px;
}
ul.golden-menu li {
  background-image: url('/assets/img/menu-normalni.png');
  background-position: top center;
  font-size: 18px;
  margin: 0;
  padding-left: 10px;
  height: 44px;
}
@media (max-width: 992px) {
  ul.golden-menu li {
    font-size: 15px;
  }
}
ul.golden-menu li:hover {
  background-image: url('/assets/img/menu-aktivni.png');
}
ul.golden-menu li a {
  color: black;
}
ul.golden-menu li a:hover {
  color: white;
  background: none;
  border: none;
}
.bordered-container {
  border: 7px black solid;
  border-radius: 5px;
  background-color: black;
}
@media (max-width: 768px) {
  .bordered-container {
    border: none;
  }
}
.media {
  margin-bottom: 30px;
}
.media .media-body {
  padding: 0 15px;
}
.media .media-body h4 {
  text-decoration: underline;
}
.media .media-object {
  max-width: 120px;
}
.comments .media {
  margin-bottom: 30px;
}
.comments .media .media-body {
  padding: 0 15px;
}
.comments .media .media-body h4 {
  text-decoration: none;
}
.comments .media .media-body h4 small {
  font-style: italic;
}
.main_banner {
  position: relative;
}
.main_banner .footer {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: #fff;
  padding: 10px;
  height: auto;
}
.main_banner .db_info {
  position: absolute;
  right: 5%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 15px;
  width: 30%;
  top: 10%;
}
@media (max-width: 500px) {
  .main_banner .db_info {
    display: none;
  }
}
.text-center {
  text-align: center;
}
.content_header header {
  color: #fff;
  padding: 5px 25px;
  background: #808080;
}
.content_header header h5 {
  padding: 0;
  margin: 3px;
}
.content_header header h5 small a {
  color: rgba(255, 255, 255, 0.5);
}
.footer {
  background-color: #414141;
  height: 150px;
  color: gray;
}
.bootstrap-tagsinput.form-control {
  height: auto;
}
.bootstrap-tagsinput.form-control .label {
  padding: 8px 12px;
  line-height: 40px;
}
