aside [class^=col-]{
  padding:0;
  margin-top:-5px;
  padding-right: 5px;
}

ul.golden-menu li{

  @media(max-width: @screen-md){
    font-size: 15px;
  }
  background-image: url('/assets/img/menu-normalni.png');
  background-position: top center;

  &:hover{
    background-image: url('/assets/img/menu-aktivni.png');
  }

  font-size: 18px;
  margin: 0;
  padding-left: 10px;
  height: 44px;


  a{
    color: black;
    &:hover{
      color: white;
      background: none;
      border: none;
    }
  }



}