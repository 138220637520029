
.galeria > div[class^=col-]{
  padding:5px;
}

.thumbnail{
  margin:0;
}

.box, .box-banner{
  position:relative;
  overflow: hidden;
  height:280px;
}
@media (max-width: 768px) {
  .box{
    position:relative;
    overflow: hidden;
    height:450px;
  }


}
@media (max-width: 500px) {
  .box{
    position:relative;
    overflow: hidden;
    height:340px;
  }

  .box-banner{
    position:relative;
    overflow: hidden;
    height:180px;
  }

}

.box-big{
  height: 100%;
}

.spb{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

a.thumbnail:hover{
  text-decoration: none;
}


.galeria{
  margin-top: 55px;

  .thumbnail{
    position: relative;
    //overflow: hidden;

    &:hover > .caption{
      display: block;

    }


    .caption{
      margin-top: 15px;
      position: absolute;
      display: none;
      bottom: 4px;

      padding-top: 50px;


      width: 100%-3px;

      color: #fff;

      #gradient .vertical(transparent, #000, 0%, 100%);



      h3{

        small{
          color: #fff;
          font-style: italic;
        }
        padding:0;
        margin: 0;
      }
    }
  }


}


.single_gallery{
  footer{
    color: white;
    padding: 5px;
    text-align: center;

    h3{
      padding: 0;
      margin: 8px 5px;
    }

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

}