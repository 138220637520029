.pagination-wrapper{
  text-align: center;

  .pagination{
    li{

      &:hover{
        a, span{
          border: 1px solid #000;
          color: #fff;
        }
      }




      &.active, &.disabled{
        span{
          background: #d8cd7b;
          border: 1px solid #282828;
        }
      }

      a{
        background: #d8cd7b;
        color: #5d5409;
      }
    }
  }
}