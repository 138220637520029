@import "variables.less";
@import "mixins.less";

body{
  background-color: #676767;
  background-image: url('/assets/img/bg-hlavni.jpg');
  background-repeat: no-repeat;
  background-position: center top;
}

h1, h2, h3, h4, h5, h6{
  color: @headings-color;
}



.container-full {
  margin: 0 auto;
  width: 100%;

}


div [class^=col-] {

padding-top: 5px;
padding-bottom: 5px;/*
background-color: #eee;
background-color: rgba(86,61,124,.15);
border: 1px solid #ddd;
border: 1px solid rgba(86,61,124,.2);*/
}

@import "gallery.less";
@import "page_header.less";

@import "pagination.less";
@import "aside_menu.less";



.bordered-container{
  border: 7px black solid;
  border-radius: 5px;

  background-color: black;

  @media(max-width: @screen-sm){
    border: none;
  }

}




.media{
  margin-bottom: 30px;
  .media-body{
    padding: 0 15px;
    h4{
      text-decoration: underline;
    }
  }

  .media-object {
    max-width: 120px;
  }
}
.comments {
  .media {
    margin-bottom: 30px;
    .media-body {
      padding: 0 15px;
      h4 {
        text-decoration: none;
        small{
          font-style: italic;
        }
      }
    }
  }
}

.main_banner{
  position:relative;
  .footer{
    position: absolute;
    bottom:0;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    color: #fff;
    padding: 10px;
    height:auto;

  }
  .db_info{
    position: absolute;
    right:5%;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    padding: 15px;
    width: 30%;
    top: 10%;
    @media (max-width: 500px) {
      display: none;
    }
  }
}





.text-center{
  text-align: center;
}





.content_header{
  header{
    color: #fff;
    padding: 5px 25px;
    background: rgb(128, 128, 128);

    h5{
      padding:0;
      margin: 3px;
      small{
        a{
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}





.footer{
  background-color: #414141;
  height:150px;
  color: gray;
}


@import "create_mineral.less";