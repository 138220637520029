.bootstrap-tagsinput.form-control{
  //min-height: 40px;
  //padding: 0;
  height: auto;

  .label{
    padding: 8px 12px;
    line-height: 40px;
  }

}